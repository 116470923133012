// import
import React from 'react';
import Login from 'views/Pages/Login';
import Orders from 'views/Pages/Order/Orders';
import OrderAdd from 'views/Pages/Order/OrderAdd';

import {
  HomeIcon,
  DocumentIcon,
  InvoiceIcon,
  AccountIcon,
  OrderTemplateIcon,
} from 'components/Icons/Icons';
import OrderTemplates from 'views/Pages/OrderTemplate/OrderTemplates';
import Users from 'views/Pages/User';
import CUOrderTemplate from 'views/Pages/OrderTemplate/CUOrderTemplate';

var dashRoutes = [
  {
    path: 'orders',
    isMenu: true,
    name: 'Danh sách hóa đơn',
    rtlName: '',
    icon: <InvoiceIcon color="inherit" />,
    component: Orders,
    layout: '/',
  },
  {
    path: 'order-add',
    isMenu: false,
    name: 'OrderAdd',
    rtlName: '',
    icon: <HomeIcon color="inherit" />,
    component: OrderAdd,
    layout: '/',
  },
  // {
  //   path: 'order-templates',
  //   isMenu: true,
  //   name: 'Danh sách mẫu hóa đơn',
  //   rtlName: '',
  //   icon: <OrderTemplateIcon color="inherit" />,
  //   component: OrderTemplates,
  //   layout: '/',
  // },
  // {
  //   path: 'cu-order-template/:id',
  //   isMenu: false,
  //   name: 'Tạo mẫu hoá đơn',
  //   rtlName: '',
  //   icon: <OrderTemplateIcon color="inherit" />,
  //   component: CUOrderTemplate,
  //   layout: '/',
  // },
  {
    path: 'users',
    isMenu: true,
    name: 'Quản lý tài khoản',
    rtlName: '',
    icon: <AccountIcon color="inherit" />,
    component: Users,
    layout: '/',
  },
  {
    name: 'ACCOUNT PAGES',
    isMenu: false,
    category: 'auth',

    rtlName: '',
    state: 'pageCollapse',
    views: [
      {
        path: '/login',
        name: 'Login',
        rtlName: '',
        icon: <DocumentIcon color="inherit" />,
        component: Login,
        layout: '/auth',
      },
    ],
  },
];
export default dashRoutes;

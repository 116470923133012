// Chakra imports
import React, { useEffect } from 'react';
import {
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Stack,
  Divider,
  Box,
  useToast,
  Wrap,
  WrapItem,
  Center,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { useForm } from 'react-hook-form';
import { InputForm } from 'components/InputForm/InputForm';
import { orderTemplateActions } from 'redux/actions';
import queryString from 'query-string';

function CUOrderTemplate() {
  const toast = useToast();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const fields = [
    {
      variableName: 'Phòng ban',
      variableValue: 'Department',
      groupName: 'Thông tin chung',
    },
    {
      variableName: 'Tên hoá đơn',
      variableValue: 'OrderName',
      groupName: 'Thông tin chung',
    },
    {
      variableName: 'Tên người bán',
      variableValue: 'Saler',
      groupName: 'Người bán',
    },
    {
      variableName: 'Mã số thuế',
      variableValue: 'TaxCodeSale',
      groupName: 'Người bán',
    },
    {
      variableName: 'Tên người mua',
      variableValue: 'Buyer',
      groupName: 'Người mua',
    },
    {
      variableName: 'Mã số thuế',
      variableValue: 'TaxCodeBuy',
      groupName: 'Người mua',
    },
    {
      variableName: 'Tính chất',
      variableValue: 'TinhChat',
      groupName: 'Danh sách dịch vụ',
    },
    {
      variableName: 'Mã hàng hoá dịch vụ',
      variableValue: 'Service Code',
      groupName: 'Danh sách dịch vụ',
    },
  ];
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    if (params.id === 'create') {
      dispatch(
        orderTemplateActions.createOrderTemplate(values, {
          success: () => {
            history.goBack();
            reset();
            toast({
              position: 'top-right',
              render: () => (
                <Box color="white" p={3} bg="telegram.500">
                  Tạo mẫu hoá đơn thành công
                </Box>
              ),
            });
          },
          failed: (mess) => {
            toast({
              position: 'top-right',
              render: () => (
                <Box color="white" p={3} bg="red.500">
                  Tạo mẫu hoá đơn thất bại {mess}
                </Box>
              ),
            });
          },
        })
      );
      return;
    }
    dispatch(
      orderTemplateActions.updateOrderTemplate(params.id, values, {
        success: () => {
          history.goBack();
          reset();
          toast({
            position: 'top-right',
            render: () => (
              <Box color="white" p={3} bg="telegram.500">
                Cập nhật mẫu hoá đơn thành công
              </Box>
            ),
          });
        },
        failed: (mess) => {
          toast({
            position: 'top-right',
            render: () => (
              <Box color="white" p={3} bg="red.500">
                Cập nhật mẫu hoá đơn thất bại {mess}
              </Box>
            ),
          });
        },
      })
    );
  };

  const handleGetFields = () => {
    dispatch(
      orderTemplateActions.getFieldsOrderTemplate(
        params.id,
        queryString.stringify({}),
        {
          success: () => {
            handleGetOrderTemplate;
          },
        }
      )
    );
  };

  const handleGetOrderTemplate = () => {
    if (params.id === 'create') return;
    dispatch(
      orderTemplateActions.getOrderTemplate(
        params.id,
        queryString.stringify({}),
        {
          success: (obj) => {
            // set data
            Object.keys(obj).forEach((key) => {
              setValue(key, obj[key]);
            });
          },
        }
      )
    );
  };

  useEffect(() => {
    handleGetFields();
  }, [params?.id]);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <VStack mb={3}>
              <HStack width={'100%'} justifyContent={'space-between'}>
                <Text
                  w={'100%'}
                  fontSize={16}
                  color={'#054169'}
                  fontWeight="bold"
                >
                  Định nghĩa file XML
                </Text>
                <HStack>
                  <Button
                    onClick={() => {
                      history.goBack();
                    }}
                    fontSize={[10, 12, 14, 16]}
                    colorScheme="gray"
                    textTransform={'uppercase'}
                  >
                    Quay lại
                  </Button>
                  <Button
                    onClick={() => onSubmit(true)}
                    fontSize={[10, 12, 14, 16]}
                    colorScheme="orange"
                    textTransform={'uppercase'}
                  >
                    Lưu và thoát
                  </Button>
                  <Button
                    onClick={() => onSubmit(false)}
                    fontSize={[10, 12, 14, 16]}
                    colorScheme="orange"
                    textTransform={'uppercase'}
                  >
                    Lưu và tiếp tục
                  </Button>
                </HStack>
              </HStack>
            </VStack>
            <Divider />
          </CardHeader>
          <CardBody mb={5}>
            <InputForm
              code="name"
              error={errors.name}
              label="Tên loại hoá đơn"
              placeholder="Nhập tên loại hoá đơn"
              register={register('name', {})}
              w={'24%'}
            />
            <Text w={'100%'} fontSize={15} color={'#054169'} fontWeight="bold">
              Định nghĩa trường dữ liệu
            </Text>
            <Wrap justify="space-between" mt={'16px'}>
              {_.chain(fields)
                .groupBy('groupName')
                .map((value, key) => ({
                  key,
                  arr: value,
                }))
                .value()
                .map((item) => {
                  return (
                    <WrapItem>
                      <Box w="380px">
                        <Text
                          mb={'8px'}
                          w={'100%'}
                          fontSize={14}
                          color={'#054169'}
                          fontWeight="bold"
                        >
                          {item.key}
                        </Text>
                        {item.arr.map((obj) => {
                          return (
                            <InputForm
                              code={obj.variableValue}
                              error={errors.name}
                              label={obj.variableName}
                              fontSizeLabel={'13px'}
                              placeholder={`Nhập ${obj.variableName.toLowerCase()}`}
                              register={register(obj.variableValue, {})}
                            />
                          );
                        })}
                      </Box>
                    </WrapItem>
                  );
                })}
            </Wrap>
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default CUOrderTemplate;

const constant = {
  ACCOUNT_LOGIN_REQUEST: 'ACCOUNT_LOGIN_REQUEST',
  ACCOUNT_LOGIN_SUCCESS: 'ACCOUNT_LOGIN_SUCCESS',
  ACCOUNT_LOGIN_FAILED: 'ACCOUNT_LOGIN_FAILED',

  ACCOUNT_LOGOUT_REQUEST: 'ACCOUNT_LOGOUT_REQUEST',
  ACCOUNT_LOGOUT_SUCCESS: 'ACCOUNT_LOGOUT_SUCCESS',
  ACCOUNT_LOGOUT_FAILED: 'ACCOUNT_LOGOUT_FAILED',

  ACCOUNT_FORGOT_PASSWORD_REQUEST: 'ACCOUNT_FORGOT_PASSWORD_REQUEST',
  ACCOUNT_FORGOT_PASSWORD_SUCCESS: 'ACCOUNT_FORGOT_PASSWORD_SUCCESS',
  ACCOUNT_FORGOT_PASSWORD_FAILED: 'ACCOUNT_FORGOT_PASSWORD_FAILED',

  ACCOUNT_RESET_PASSWORD_REQUEST: 'ACCOUNT_RESET_PASSWORD_REQUEST',
  ACCOUNT_RESET_PASSWORD_SUCCESS: 'ACCOUNT_RESET_PASSWORD_SUCCESS',
  ACCOUNT_RESET_PASSWORD_FAILED: 'ACCOUNT_RESET_PASSWORD_FAILED',

  GET_ACCOUNTS_REQUEST: 'GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILED: 'GET_ACCOUNTS_FAILED',

  GET_CURRENT_ACCOUNT_REQUEST: 'GET_CURRENT_ACCOUNT_REQUEST',
  GET_CURRENT_ACCOUNT_SUCCESS: 'GET_CURRENT_ACCOUNT_SUCCESS',
  GET_CURRENT_ACCOUNT_FAILED: 'GET_CURRENT_ACCOUNT_FAILED',

  ACCOUNT_CHANGE_PASSWORD_REQUEST: 'ACCOUNT_CHANGE_PASSWORD_REQUEST',
  ACCOUNT_CHANGE_PASSWORD_SUCCESS: 'ACCOUNT_CHANGE_PASSWORD_SUCCESS',
  ACCOUNT_CHANGE_PASSWORD_FAILED: 'ACCOUNT_CHANGE_PASSWORD_FAILED',

  GET_ACCOUNT_REQUEST: 'GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS: 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAILED: 'GET_ACCOUNT_FAILED',

  SET_QUERY_ORDER: 'SET_QUERY_ORDER',

  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILED: 'CREATE_ACCOUNT_FAILED',

  UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILED: 'UPDATE_ACCOUNT_FAILED',

  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILED: 'DELETE_ACCOUNT_FAILED',

  SET_PASSWORD_REQUEST: 'SET_PASSWORD_REQUEST',
  SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_FAILED: 'SET_PASSWORD_FAILED',

  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILED: 'GET_ROLES_FAILED',

  GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
  GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
  GET_ROLE_FAILED: 'GET_ROLE_FAILED',

  GET_ROLE_PERMISSION_REQUEST: 'GET_ROLE_PERMISSION_REQUEST',
  GET_ROLE_PERMISSION_SUCCESS: 'GET_ROLE_PERMISSION_SUCCESS',
  GET_ROLE_PERMISSION_FAILED: 'GET_ROLE_PERMISSION_FAILED',

  CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILED: 'CREATE_ROLE_FAILED',

  UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILED: 'UPDATE_ROLE_FAILED',

  DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILED: 'DELETE_ROLE_FAILED',

  GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILED: 'GET_INVOICE_FAILED',

  GET_INVOICES_REQUEST: 'GET_INVOICES_REQUEST',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILED: 'GET_INVOICES_FAILED',

  SAVE_INVOICES_REQUEST: 'SAVE_INVOICES_REQUEST',
  SAVE_INVOICES_SUCCESS: 'SAVE_INVOICES_SUCCESS',
  SAVE_INVOICES_FAILED: 'SAVE_INVOICES_FAILED',

  SAVE_INVOICE_REQUEST: 'SAVE_INVOICE_REQUEST',
  SAVE_INVOICE_SUCCESS: 'SAVE_INVOICE_SUCCESS',
  SAVE_INVOICE_FAILED: 'SAVE_INVOICE_FAILED',

  UPDATE_SAVE_INVOICE_STATUS_REQUEST: 'UPDATE_SAVE_INVOICE_STATUS_REQUEST',
  UPDATE_SAVE_INVOICE_STATUS_SUCCESS: 'UPDATE_SAVE_INVOICE_STATUS_SUCCESS',
  UPDATE_SAVE_INVOICE_STATUS_FAILED: 'UPDATE_SAVE_INVOICE_STATUS_FAILED',

  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILED: 'GET_PRODUCTS_FAILED',

  GET_PURCHASE_PURPOSES_REQUEST: 'GET_PURCHASE_PURPOSES_REQUEST',
  GET_PURCHASE_PURPOSES_SUCCESS: 'GET_PURCHASE_PURPOSES_SUCCESS',
  GET_PURCHASE_PURPOSES_FAILED: 'GET_PURCHASE_PURPOSES_FAILED',

  GET_STOCKS_REQUEST: 'GET_STOCKS_REQUEST',
  GET_STOCKS_SUCCESS: 'GET_STOCKS_SUCCESS',
  GET_STOCKS_FAILED: 'GET_STOCKS_FAILED',

  GET_STATISTICS_REQUEST: 'GET_STATISTICS_REQUEST',
  GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
  GET_STATISTICS_FAILED: 'GET_STATISTICS_FAILED',

  CREATE_INVOICE_REQUEST: 'CREATE_INVOICE_REQUEST',
  CREATE_INVOICE_SUCCESS: 'CREATE_INVOICE_SUCCESS',
  CREATE_INVOICE_FAILED: 'CREATE_INVOICE_FAILED',

  GET_ORDER_TEMPLATES_REQUEST: 'GET_ORDER_TEMPLATES_REQUEST',
  GET_ORDER_TEMPLATES_SUCCESS: 'GET_ORDER_TEMPLATES_SUCCESS',
  GET_ORDER_TEMPLATES_FAILED: 'GET_ORDER_TEMPLATES_FAILED',

  GET_ORDER_TEMPLATE_REQUEST: 'GET_ORDER_TEMPLATE_REQUEST',
  GET_ORDER_TEMPLATE_SUCCESS: 'GET_ORDER_TEMPLATE_SUCCESS',
  GET_ORDER_TEMPLATE_FAILED: 'GET_ORDER_TEMPLATE_FAILED',

  CREATE_ORDER_TEMPLATE_REQUEST: 'CREATE_ORDER_TEMPLATE_REQUEST',
  CREATE_ORDER_TEMPLATE_SUCCESS: 'CREATE_ORDER_TEMPLATE_SUCCESS',
  CREATE_ORDER_TEMPLATE_FAILED: 'CREATE_ORDER_TEMPLATE_FAILED',

  UPDATE_ORDER_TEMPLATE_REQUEST: 'UPDATE_ORDER_TEMPLATE_REQUEST',
  UPDATE_ORDER_TEMPLATE_SUCCESS: 'UPDATE_ORDER_TEMPLATE_SUCCESS',
  UPDATE_ORDER_TEMPLATE_FAILED: 'UPDATE_ORDER_TEMPLATE_FAILED',

  DELETE_ORDER_TEMPLATE_REQUEST: 'DELETE_ORDER_TEMPLATE_REQUEST',
  DELETE_ORDER_TEMPLATE_SUCCESS: 'DELETE_ORDER_TEMPLATE_SUCCESS',
  DELETE_ORDER_TEMPLATE_FAILED: 'DELETE_ORDER_TEMPLATE_FAILED',

  GET_FIELDS_ORDER_TEMPLATE_REQUEST: 'GET_FIELDS_ORDER_TEMPLATE_REQUEST',
  GET_FIELDS_ORDER_TEMPLATE_SUCCESS: 'GET_FIELDS_ORDER_TEMPLATE_SUCCESS',
  GET_FIELDS_ORDER_TEMPLATE_FAILED: 'GET_FIELDS_ORDER_TEMPLATE_FAILED',

  GET_COMPANYS_REQUEST: 'GET_COMPANYS_REQUEST',
  GET_COMPANYS_SUCCESS: 'GET_COMPANYS_SUCCESS',
  GET_COMPANYS_FAILED: 'GET_COMPANYS_FAILED',

  RESET_INVOICE_REQUEST: 'RESET_INVOICE_REQUEST',
};

export default constant;

// Chakra imports
import {
  Button,
  Flex,
  HStack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
} from '@chakra-ui/react';
import _ from 'lodash';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TableRow from './components/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { orderTemplateActions } from 'redux/actions';
import { Paginate } from 'react-paginate-chakra-ui';
import queryString from 'query-string';

function OrderTemplates() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderTemplates } = useSelector((state) => state.orderTemplateReducer);
  const results = _.get(orderTemplates, 'results', []);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    handleGetOrderTemplates();
  }, [query]);

  const handleGetOrderTemplates = () => {
    dispatch(
      orderTemplateActions.getOrderTemplates(queryString.stringify(query))
    );
  };

  const handleDelete = (id) => {
    dispatch(
      orderTemplateActions.deleteOrderTemplate(id, {
        success: () => {
          handleClose();
          toast({
            position: 'top-right',
            render: () => (
              <Box color="white" p={3} bg="telegram.500">
                Xoá thành công
              </Box>
            ),
          });
        },
        failed: (mess) => {
          toast({
            position: 'top-right',
            render: () => (
              <Box color="white" p={3} bg="red.500">
                Xoá thất bại {mess}
              </Box>
            ),
          });
        },
      })
    );
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <HStack justifyContent={'space-between'}>
            <Text
              fontSize={[10, 12, 14, 16]}
              color={'#054169'}
              fontWeight="bold"
            >
              DANH SÁCH MẪU HÓA ĐƠN
            </Text>
            <Button
              onClick={() => {
                history.push('/cu-order-template/create');
              }}
              fontSize={[10, 12, 14, 16]}
              colorScheme="blue"
            >
              THÊM MỚI
            </Button>
          </HStack>
        </CardHeader>
        <CardBody h={'60vh'} overflowX="scroll">
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Loại mẫu hóa đơn
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Ngày tạo
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Ngày cập nhật
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Người tạo
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  display={'flex'}
                  flexDir={'row'}
                  justifyContent={'center'}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Hành động
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {results.map((row, index, arr) => {
                return (
                  <TableRow
                    row={row}
                    isLast={index === arr.length - 1}
                    key={index}
                    onEdit={() => {}}
                    onDelete={() => handleDelete(row.id)}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Paginate
            page={query.page - 1}
            count={orderTemplates?.totalResults ?? 0}
            pageSize={10}
            onPageChange={(value) => {
              setQuery({ ...query, page: value + 1 });
            }}
            margin={2}
            shadow="lg"
            fontWeight="blue"
            variant="outline"
            border="2px solid #3182ce"
            colorScheme="blue"
          />
        </div>
      </Card>
    </Flex>
  );
}

export default OrderTemplates;

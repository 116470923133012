function isInt(n) {
  return n % 1 === 0;
}
export const isNumeric = (value) => {
  if (value === '') return true;

  return !isNaN(Number(value)) && /([0-9]*[.])?[0-9]+/.test(value);
};

export const currencyFormat = (num) => {
  try {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } catch (error) {
    return 0;
  }
};

export const calculateResult = (result) => {
  const ret = result;
  const { ExchangeRate } = result.purcharInvoice;
  let TotalAmountOC = 0;
  let TotalVatAmountOC = 0;
  for (let i = 0; i < ret.purcharInvoiceDetails.length; i += 1) {
    const detail = ret.purcharInvoiceDetails[i];
    const amountOC = Number(detail.UnitPriceOC) * Number(detail.Quantity);
    TotalAmountOC += amountOC;
    detail.AmountOC = Number(detail.UnitPriceOC) * Number(detail.Quantity);
    detail.Amount = amountOC * ExchangeRate;

    detail.OutwardPriceOC = Number(detail.UnitPriceOC);
    detail.OutwardPrice = Number(detail.UnitPriceOC) * ExchangeRate;

    detail.OutwardAmountOC = amountOC;
    detail.OutwardAmount = amountOC * ExchangeRate;

    detail.OutwardPriceConvertOC = amountOC;
    detail.OutwardPriceConvert = amountOC * ExchangeRate;

    detail.TurnOverAmountOC = amountOC;
    detail.TurnOverAmount = amountOC * ExchangeRate;

    TotalVatAmountOC += detail.VATAmountOC;
    ret.purcharInvoiceDetails[i] = detail;
  }
  ret.purcharInvoice.TotalAmountOC = TotalAmountOC;
  ret.purcharInvoice.TotalTurnOverAmountOC = TotalAmountOC;

  ret.purcharInvoice.TotalVATAmountOC = TotalVatAmountOC;
  ret.purcharInvoice.TotalVATAmount = TotalVatAmountOC * ExchangeRate;

  ret.purcharInvoice.TotalAmount = TotalAmountOC * ExchangeRate;
  ret.purcharInvoice.TotalTurnOverAmount = TotalAmountOC * ExchangeRate;
  return ret;
};
